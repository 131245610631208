@use "palette";
@use '@angular/material' as mat;
@use "@material/icon-button";
@import "styles/ux-variables";
@import "styles/picker";
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "styles/material";


$my-primary: mat.m2-define-palette(palette.$hunch-primary-palette);
$my-accent: mat.m2-define-palette(palette.$hunch-accent-palette);
$my-theme: mat.m2-define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  )
));


@include mat.core;
@include mat.core-theme($my-theme);
@include mat.button-theme($my-theme);
@include mat.all-component-themes($my-theme);


html, body {
  min-height: 100vh;
  height: auto;
  margin: 0;
  font-weight: $font-weight-regular;
  font-size: $font-regular-size;


  h1 {
    font-size: $font-heading-1-size !important;
    font-weight: $font-weight-bold !important;
  }

  h2 {
    font-size: $font-heading-2-size !important;
    font-weight: $font-weight-medium !important;
    margin: 0 !important;
  }

  h3 {
    font-size: $font-heading-3-size !important;
    font-weight: $font-weight-bold !important;
  }

  h4 {
    font-size: $font-heading-4-size !important;
    font-weight: $font-weight-medium !important;
  }

  .body-big-bold {
    font-size: $font-large-size;
    font-weight: $font-weight-bold;
  }

  .body-big-medium {
    font-size: $font-large-size;
    font-weight: $font-weight-medium;
  }

  .body-big-regular {
    font-size: $font-large-size;
    font-weight: $font-weight-regular;
  }

  .body-medium-bold {
    font-size: $font-regular-size;
    font-weight: $font-weight-bold;
  }

  .body-medium-medium {
    font-size: $font-regular-size;
    font-weight: $font-weight-medium;
  }

  .body-medium-regular {
    font-size: $font-regular-size;
    font-weight: $font-weight-regular;
  }

  .body-small-bold {
    font-size: $font-small-size;
    font-weight: $font-weight-bold;
  }

  .body-small-medium {
    font-size: $font-small-size;
    font-weight: $font-weight-medium;
  }

  .body-small-regular {
    font-size: $font-small-size;
    font-weight: $font-weight-regular;
  }

  .disabled-overlay {
    background: rgb(55 62 72 / 20%);
    position: absolute;
    inset: 0;
    margin: auto;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.game-builder {
  .mat-mdc-dialog-container {
    .mdc-dialog__container {
      .mat-mdc-dialog-surface {
        overflow: visible;
      }
    }
  }
}


.cdk-overlay-container {
  &.mystyle {
    .cdk-global-overlay-wrapper {
      z-index: 999;
    }
  }

  &.event {
    .cdk-overlay-connected-position-bounding-box {
      .cdk-overlay-pane {
        &.mat-mdc-autocomplete-panel-above {
          .mat-mdc-autocomplete-panel {
            border-radius: $border-radius $border-radius 0 0;
            border-top: none;
            box-shadow: 4px -8px 9px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
          }
        }

        .mat-mdc-autocomplete-panel {
          border-bottom-left-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
          box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
        }
      }
    }
  }
}

.answer-panel-class {
  width: 460px !important;
}

.event-panel-class {
  margin-top: 1px !important;
}

.custom-autocomplete-style {
  .mat-mdc-option:not(:hover) {
      background: none !important;
  }

  .mat-mdc-option:hover {
    background: rgb(0 0 0 / 4%) !important;
  }


  .mat-mdc-option {
    position: relative;
    padding: 0!important;

    .custom-wrapper {
      position: absolute;
      inset: 4px 0 0;
    }

    .mdc-list-item__primary-text {
      .mat-mdc-checkbox {
        padding-left: 16px;

        .mdc-form-field {
          label {
            display: flex;
            height: 40px;
            place-items: center;
          }
        }
      }
    }
  }
}

.locale-autocomplete-style {
  .mat-mdc-option {
    .mdc-list-item__primary-text {
      .mat-option-item {
        .text-item-part {
          display: flex;
          align-items: center;

          img {
            padding-right: 10px;
            width: 22px;
            height: 16px;
          }

          .empty-image {
            padding-right: 10px;
            width: 22px;
            height: 16px;
          }
        }
      }
    }
  }
}

.builder-tabs-container {
  overflow: visible;
  height: 100%;
  width: 100%;

  .builder-wrapper {
    background: white;
    height: 100%;
    z-index: 9999;
    display: block;
    top: 0;
    left: 0;
    right: 0;

    .header-pannel {
      padding: 24px 24px 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .header-container {
        padding-left: 10px;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        display: flex;

        .general-header {
          display: flex;
          flex-direction: row;

          .close {
            cursor: pointer!important;
            color: $gray-color-500;
            margin-right: 20px;
          }
        }

        .button-container {
          display: flex;
          flex-direction: row;
        }
      }
    }

    .tabs-details {
      height: calc(100% - 72px);
    }
  }

  .mat-mdc-tab-group {
    background: $gray-color-100;
    height: 100%;

    .mat-mdc-tab-header {
      background: white;
      padding: 24px 24px 0;
      width: auto;

      .mat-mdc-tab-list {
        flex-grow: initial;
      }
    }

    .mat-mdc-tab-body-wrapper {
      margin: 24px;
      box-shadow: 0 2px 24px rgb(0 0 0 / 10%);

      .mat-mdc-tab-body {
        height: 100%;

        .mat-mdc-tab-body-content {
          padding: 24px;
          background: white;
          border-radius: $border-radius;
          border: 1px solid rgb(0 0 0 / 15%);
          height: calc(100% - 50px);
          overflow: auto;
          box-shadow: 0 2px 24px rgb(0 0 0 / 10%);
        }
      }
    }
  }
}

.ticket-form-container, .action-area {
  display: flex;
  gap: 50px;

  .mat-mdc-slide-toggle {
    .mdc-switch--checked {
      &.mdc-switch--disabled {
        .mdc-switch__track {
          opacity: 1;
        }
      }
    }
  }
}
